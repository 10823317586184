// @import '~bootswatch/dist/quartz/variables';

$border-radius: 0.2rem !default;
$border-radius-sm: 0.4rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-pill: 50rem !default;

@import url('https://use.typekit.net/zam2jwv.css');
@import '~bootstrap/scss/bootstrap';
@import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: 'proxima-nova', sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

.model-inspector {
  canvas {
    overflow: hidden;
    overscroll-behavior: none;
    touch-action: none;
    background-color: transparent;
    height: 600px !important;
  }
}

.ar-viewer {
  overflow: hidden;
  overscroll-behavior: none;
  touch-action: none;
  background-color: black;
  z-index: 0;
}

.enter-ar-icon {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.8);
  padding: 0.6rem;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 1);
  }
}

.bg-dark {
  background-color: rgba(0, 0, 0, 1) !important;
}

.bg-light {
    background-color: #fff !important
}

.action-bar {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  align-items: center;
  padding: 1rem;
  height: 5rem;
}

.layer-wrapper {
  min-height: 50vh;
}

*:focus {
  outline: none;
}

.load-button {
  background: $primary !important;
}

.offcanvas-body {
  background-color: #fff;
}

.dropzone-marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 0 5px #bbb;
  cursor: pointer;

  &.selected {
    width: 80px;
    height: 80px;
    border: 5px solid #000;
  }
}

.map_box_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // prevent mapbox from stealing focus
  pointer-events: none;
  z-index: 0;
  opacity: 0.5;
}

.map_box_mini_container {
  position: relative;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-grow: 1;
  min-height: 300px;
}

#map {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.mapboxgl-ctrl-top-right {
  margin-top: 2.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

// Layer Overview on map page

.layer-overview-wrapper {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  .layer-overview-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 1rem;
    border: 5px solid #fff;
    // border-radius: 200px;
  }
}

// Bootstrap overrides

// hide torus wallet button
iframe#torusIframe.torusIframe {
  display: none !important;
}

button {
  border-radius: 0.25rem !important;
  white-space: nowrap;
}

// AR Viewer page

.ar-viewer__action-button {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  background: transparent;
  color: white;
}

.ar-viewer__button-group {
  position: absolute;
  bottom: 0;
  z-index: 100;
  right: 0;
  width: 100%;
  padding: 1rem;
}

.breadcrumb {
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 0.5rem;

  .breadcrumb-item {
    color: #ffffff90;
    a {
      color: #fff !important;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #fff !important;
      }
    }
    &::before {
      color: #fff;
    }
  }
}

// Action stack - The icons that appear on the layer and asset cards

.action-stack-float {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.action-stack__icon {
  cursor: pointer;
  padding: 0.4rem 0.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;

  background: #fff;
}

.layer-action__icon {
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  background: #000;
  svg {
    color: #fff !important;
  }
  &:hover {
    opacity: 0.8;
  }
}

.pulse {
  position: relative;
  transform-origin: center;
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.1);
  }
}

// Layer page

.layer-header {
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1rem;
  min-height: 250px;
  // max-height: 450px;
  // height: 45vw;
  // display: flex;
  // align-items: flex-end;

  position: relative;

    @media (min-width: 768px) {
  min-height: 350px;
  }

  @media (min-width: 992px) {
  min-height: 450px;
  }


}
.layer-details {
  padding: 1rem;
  width: 100%;

  a {
    color: #111;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #111;
      text-decoration: none;
    }
  }
}

// Profile header

.profile-header {
  color: #000;
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)), url('/assets/header.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #f8f8f8;
  font-size: 1.1rem;
  padding: 2rem;

  a {
     color: #111; 
     text-decoration: none;
  }
}

// Assets card

.asset-inactive {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.4;
  background: repeating-linear-gradient(45deg, #039be5 0px, #039be5 20px, #90caf9 20px, #90caf9 40px);
  overflow: hidden;
}

.asset-inactive::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
  pointer-events: none;
}

// list group (side panel menu)

.list-group-item {
  border-color: #eaeaea; /* Change the border color */
  background-color: #f8f9fa; /* Change the background color */
  color: #333; /* Change the text color */
  transition: all 0.3s ease; /* Add smooth transition for hover effects */
  font-family: 'proxima-nova', sans-serif; /* Change the font-family */
  font-weight: 500; /* Adjust the font-weight */
  border-radius: 5px; /* Add some border-radius */
  margin-bottom: 5px; /* Add some margin between items */
}

.list-group-item:hover {
  background-color: #e8e8e8; /* Change the background color on hover */
  color: #222; /* Change the text color on hover */
  transform: translateY(-2px); /* Add a subtle lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box-shadow on hover */
}

.list-group-item.active {
  background-color: #007bff; /* Change the background color for active item */
  color: #fff; /* Change the text color for active item */
  border-color: #007bff; /* Change the border color for active item */
}

.filter-sort-bar {
  width: 100%;
  padding: 1rem 0;
  border-radius: 0.5rem;
}


// Collection success modal

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.3) translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.collected-modal {
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collected-modal__message {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem 3rem;
  border-radius: 2rem;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  animation: bounceIn 1s ease;
  opacity: 1;
  visibility: visible;
  @media (min-width: 992px) {
    margin: 8rem;
  }
}

@media (max-width: 500px) {
  .Select input {
    font-size: 16px !important;
  }
}

// React confirm alert

.react-confirm-alert-body {
  h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

// portal-gallery

.portal-gallery {
  // position: absolute;
  // bottom: 0;
  background: rgba(255, 255, 255, 0.85);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
}

// Portals

.portal-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  font-size: 2rem;
  color: white;

  border: 2px solid #ddd;

  &.active {
    padding: 0;
  }
  &.empty {
    border-color: #ddd;
    background-color: #ddd;
  }

  &.preview {
    min-width: 10%;
    height: auto;
  }
}

.asset-page {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.7);

  .asset-page__header {
    margin-bottom: 1rem;
    .asset-page__header__title {
      font-size: 1.5rem;
      font-weight: bold;
    }
    .asset-page__header__subtitle {
      font-size: 1rem;
      color: #999;
    }
  }
}

// CLEAN STYLES

// Bootstrap buttons - TODO: Configure the default variables for these

.btn-primary {
  background-color: #222 !important;
  border-color: #222 !important;
  color: white !important;
}

.btn-secondary {
  background-color: #fc0184 !important;
  border-color: #fc0184 !important;
  color: white !important;
}

.btn-success {
  background-color: #078110 !important;
  border-color: #078110 !important;
  color: white !important;
}

// Main Nav Bar and offset menu

.navbar__profile-image {
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
  object-fit: cover;
  &:hover {
    opacity: 1;
  }
}

// Custom Tab pills

.nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
  .nav-link {
    border: 0;
    border-radius: 0;
    color: #333;
    font-weight: 500;
    transition: none;
   &:hover {

    border-radius: 0!important;
    border-bottom: 5px solid #ddd;
    }

  }
  .nav-link.active {
    border: 0;
    border-radius: 0!important;
    color: #333;
    background-color: transparent;
    border-bottom: 5px solid #333;

  }
}

.nav-pills {
  .nav-link.active {
    background-color: #000 !important;
    color: white !important;
    &:hover {
      background-color: #000 !important;
      color: white !important;
    }
  }

  .nav-item {
    margin: 0 0.5rem 1rem;
    background-color: #fff !important;
    color: #000 !important;

    button {
      font-size: 1rem;
      padding: 0.75rem;
      border: 0 !important;
      background-color: #f0f0f0 !important;
      color: #333 !important;
      border-color: #f0f0f0 !important;
      color: #000 !important;
      font-weight: 300;
      transition: all 0.3s ease-in-out;
      // border-radius: 2rem !important;
      &:hover {
        background-color: #eee !important;
        color: #333 !important;
        border-color: #f0f0f0 !important;
      }
    }
  }

  .nav-link {
    color: white !important;
    font-weight: 500;
    font-size: 1.2rem;
    // background-color: #f8f8f8 !important;
    // border: 1px solid #ffffff1c !important;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #000000 !important;
      color: white !important;
      border-color: #000000 !important;
    }
  }
}



.home-wrapper {

  display: flex;
  justify-content: center;
  align-items: center;


  .background-image {
  aspect-ratio: 16 / 9;
  max-height: 500px;
    background-image: url('/assets/header.png');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.55);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    display: flex;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust as needed */
  }

  .subtitle {
    font-size: 1.2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust as needed */
  }



}


// Cards

.creation-card {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  width: 100%;
  border: 1px solid #ddd;
  color: #111;
  overflow: auto;
  .card-img-top {
    width: 100%;
    height: 330px;
    object-fit: cover;
  }


}

.layer-card-wrapper {
  cursor: pointer;
  overflow: hidden;
  position: relative;

  img {
    height: 220px;
    object-fit: cover;

  }

  .layer-card-wrapper__header {

    .title {
      font-weight: bold;
      margin-bottom: 0;
      overflow: hidden;
      max-width: 90%;
      text-overflow: ellipsis;
    }
  }

  .layer-card-wrapper__body {
    background: #f9f9f9;
    padding: 0.75rem;
  }
}

// Typography

a {
  color: $dark;
  // text-decoration: none;
  cursor: pointer;
  // &:hover {
  //   text-decoration: none;
  // }
}

h4,
h5,
h6 {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 500;
}

p {
  b {
    font-weight: 600;
  }
}

.playlayer {
  font-family: 'ethnocentric', sans-serif;
  font-weight: 300;
}

.small {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

// UTILS and Aniamtion helpers

.popout {
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
}

// Model Viewer

model-viewer {
  cursor: grab;
  display: flex;
  width: 100%;
  position: relative;
  user-select: none;
  overflow: hidden;
  overscroll-behavior: none;
  touch-action: none;
  background-color: transparent;
  height: 600px !important;
}

view-button {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 12px;
  font-weight: 700;
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  width: max-content;
  height: max-content;
  transform: translate3d(-50%, -50%, 0);
}

// button {
//   display: block;
//   width: 20px;
//   height: 20px;
//   border-radius: 10px;
//   border: none;
//   background-color: blue;
//   box-sizing: border-box;
// }

@media (max-width: 500px) {
  .Select input {
    font-size: 16px;
  }
}

.social-links {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  .social-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 1.2rem 0 0;
    color: #fff;
    // opacity: 0.6;
    // &:hover {
    //   opacity: 1;
    // }
  }
}



.faqs {
  a {
    color: #007bff;
    text-decoration: underline;
  }
}



// Tables

.react-bootstrap-table table {
    table-layout: auto !important;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.react-bootstrap-table thead {
    background-color: #f7f7f7; /* subtle background for the header */
}

.react-bootstrap-table thead th {
    text-transform: uppercase; 
    font-weight: 500;
    padding: 10px 15px;
    border-bottom: 2px solid #e0e0e0; /* a thicker border for the bottom of the header cells */
    color: #555; /* darken the header text a little */
}

.react-bootstrap-table tbody td {
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2; /* a light border for the bottom of the cells */
}


/* Existing styles for screen readers */
.sr-only {
    display: none;
}

/* Your existing action icon styles, no changes made */
.table-actions {
    white-space: nowrap;

    .action-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #f5f5f5;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 8px; /* space between icons */
    }

    .action-icon:last-child {
        margin-right: 0;
    }
}

